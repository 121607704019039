import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './Home/Home';
import NotFound from './NotFound/NotFound';

export default function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route component={NotFound} />      
                </Switch>
            </div>
        </BrowserRouter>
    );
}; 