import React from 'react';
import style from "./requestDemo.module.css"

function RequestDemo({setShowModal,sendRequest,name,email,phone,contactMessage,setName,setEmail,setPhone,setContactMessage}) {

  return (
    <>
    {/* ======= Request demo modal [saif 21/06/23] ================ */}
      <div className={style["requestdemo-container"]}>
        <div className={style["requestdemo-content"]}>
            <div className={style["requestdemo-content-text-container"]}>
                <h2 className={style["requestdemo-content-text-pink"]}>Ready to go</h2>
                <h2 className={style["requestdemo-content-text-pink"]}>paperless?</h2>
                <h2 className={style["requestdemo-content-text-white"]}>Contact us</h2>
                <h2 className={style["requestdemo-content-text-white"]}>today,</h2>
            </div>
            <div className={style["requestdemo-content-icon-container-location"]}>
                <div className={style["requestdemo-content-icon"]}>
                    <img src="../images/landing-page/location.png" alt="email-icon" />
                </div>
                <div className={style["icon-text-header"]}>
                        <p className={style[
                            "icon-text-header"
                        ]}>Our location</p>
                        <p className={style[
                            "icon-text"
                        ]}>201-A, SAS Tower, SEC-38</p>
                        <p className={style[
                            "icon-text"
                        ]}>Gurugram 122001, Haryana</p>
                  
                </div>
            </div>
            <div className={style["requestdemo-content-icon-container-email"]}>
                <div className={style["requestdemo-content-icon"]}>
                    <img src="../images/landing-page/email.png" alt="email-icon" />
                </div>
                <div className={style["icon-text-header"]}>
                       <p className={style[
                            "icon-text-header"
                        ]}>Email address</p>
                       <p className={style[
                            "icon-text"
                        ]}>Contact@invincibleocean.com</p>
                </div>
            </div>
        </div>
        <div className={style["requestdemo-form-container"]}>
            <div className={style["requestdemo-form-header"]}>
                <div className={style["requestdemo-form-header-content"]}>Book demo</div>
                <div className={style["cross-icon-container"]} onClick={()=>setShowModal(false)}>
                    <img className={style["cross-icon-img"]} src="../images/times-circle.svg" alt="times-circle" />
                </div>
            </div>
            <div className={style["requestdemo-form-cnt"]}>
                <form onSubmit={sendRequest}>
                    <div>
                        <label htmlFor="name" >Name</label>
                        <input required type="text"  name="name" id="name" placeholder='Enter Name' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="email" >Email</label>
                        <input required type="email" name="email" id="email" placeholder='Enter Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="phone" >Phone Number</label>
                        <input required type="text" maxLength={10} name="number" id="phone" placeholder='Enter mob no' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="query"  > Brief description of query</label>
                        <div>
                            <textarea required className='form-control mb-3' placeholder='Enter message here' id="query" name="story"rows="5" cols="33" value={contactMessage} onChange={(e) => setContactMessage(e.target.value)}></textarea>
                        </div>
                    </div>
                    <div className={style["requestdemo-form-button-container"]}>
                        <button type='submit' className="btn primary-button">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
      </div>
      {/* ================================================================ */}
    </>
  )
}

export default RequestDemo;