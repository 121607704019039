import React, { useState } from 'react';
import api, { sendqueries } from '../Base/Config';
import Footer from '../Components/Footer/Footer';
import Faqs from '../Components/Faqs/Faqs';
import Message from '../Components/Message/Message';
import RequestDemo from '../Components/RequestDemoModal/RequestDemo';
import style from './home.module.css';

export default function Home() {
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ contactMessage, setContactMessage ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const [ loader, setLoader ] = useState(false);
    const [ showModal,setShowModal ]=useState(false);

    const faqs = [
        {
            id: 1,
            question: "What is TruSign and how is it different from a physical signature?",
            answer: "TruSign is an electronic version of a signature that can be used to sign digital documents. It is legally binding and has the same legal effect as a physical signature. The key difference is that e-signatures can be signed electronically, making it faster and more convenient to sign and manage documents."
        },
        {
            id: 2,
            question: "Is my signature secure with TruSign?",
            answer: "Yes, TruSign uses industry-standard security measures to protect your signature and your documents. This includes using SSL encryption, two-factor authentication, and other security features to ensure that your documents are secure and protected."
        },
        {
            id: 3,
            question: "Can I use TruSign to sign documents from my mobile device?",
            answer: "Yes, TruSign is mobile-friendly and can be used to sign and manage documents from your smartphone or tablet. Simply download the TruSign mobile app and log in to your account to get started."
        },
        {
            id: 4,
            question: "Does TruSign integrate with other business apps?",
            answer: "Yes, TruSign integrates with popular business apps like Salesforce, Google Drive, and Dropbox, allowing you to sign and manage documents within the apps you already use. This can help streamline your workflow and save you time."
        },
        {
            id: 5,
            question: "How long does it take to sign a document with TruSign?",
            answer: "Signing a document with TruSign is quick and easy, and can be done in just a few minutes. The exact time it takes to sign a document will depend on factors like the number of signers, the complexity of the document, and the speed at which the signers respond."
        }
    ]

    const sendRequest = (e) => {
        e.preventDefault();
        setLoader(true);
        api.post(sendqueries, {
            name: name,
            email: email,
            phone: phone,
            productName: "TruSign",
            message: contactMessage
        })
        .then((res) => {
            if(res.data.code === 100){
                setLoader(false);
                setMessageClass('success');
                setMessage(res.data.message);
                resetFunction();
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setLoader(false);
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage("Something went wrong. try again later!");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    const resetFunction = () => {
        setName('');
        setEmail('');
        setPhone('');
        setContactMessage('');
    }

    const closeMessage = () => { setMessage('') }
    
    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={closeMessage}/>}
        <section id={style["banner"]}>
            <div className='container'>
                <div className={style['banner']}>
                    <div className={style['banner-header']}>
                        <div>
                            <img src='../images/logo.png' alt='invincible-logo' />
                        </div>
                        <div>
                            <button className={style["btn-book-demo"] } onClick={()=>{ setShowModal(true)}} >
                                <div className={style["btn-book-demo-txt"]}>Book a demo</div>
                            </button>
                            <a href="https://dashboard.invincibleocean.com/esign" rel="noreferrer" target="_blank"><button className={style["btn-login"]}>Log In</button></a>
                        </div>
                    </div>
                    <div className={style['banner-central-part']}>
                        <div className={style['left-content']}>
                            <h4>
                                Sign Documents Faster and More Securely with <span>TruSign</span>
                            </h4>
                            <ul>
                                <li>Only pay for your usage (No minimum purchase required)</li>
                                <li>Aadhaar eSign starting from @Rs. 20*/document, Secure Digital sign @Rs. 10*/document.</li>
                                <li>No subscription charges.</li>
                                <li>Unlimited documents per user.</li>
                                <li>Save time and effort by using editable templates</li>
                            </ul>
                            {/* =============== certified icons by saif (20/06/2023) =====================*/}
                            <div className={style['certified-icon']}>
                                <div className={style['certified-icon-container']}>
                                    <div className={style['icon-container-iso']}><img className={style['icon-img']} src="../images/landing-page/ISO.png" alt="iso" /></div>
                                    <div className={style['icon-container-gdpr']}><img className={style['icon-img']} src="../images/landing-page/GDPR.png" alt='gdpr'/></div>
                                    <div className={style['icon-container-soc2type2']}><img className={style['icon-img']} src="../images/landing-page/Soc2Type2.png" alt='soc2type2'/></div>
                                    <div className={style['icon-container-cisa']}><img className={style['icon-img']} src="../images/landing-page/Cisa.png" alt="cisa"/></div>
                                </div>
                            </div>
                            {/* =================================================== */}
                            <div className={style['banner-action']}>
                                <a href="https://dashboard.invincibleocean.com/esign" rel="noreferrer" target="_blank"><button className="btn primary-button mr-3">Get started</button></a>
                                {/* <span><img src='../images/landing-page/youtube-icon.png' alt='youtube-icon'/> See how it works</span>  */}
                            </div>
                        </div>
                        <div className='right-side-image'>
                            <img src='../images/landing-page/banner-lady-image.png' alt='banner' />
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        <div className='container'>
            <div className={style['request-demo']}>
                 <form onSubmit={sendRequest} >
                    <div>
                        <label>Name</label>
                        <input required type="text" placeholder='Enter name' value={name} onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div>
                        <label>E mail</label>
                        <input required type="text" placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div>
                        <label>Phone number</label>
                        <input required type="text" placeholder='Enter number' value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    </div>
                    <div>
                        <button className="btn primary-button"  >Request Demo</button>
                    </div>
                </form>
            </div>
        </div>
        <section id={style['certified-company']}>
            <h5 className={style['trusted-complete']}>Our Clients</h5>
            <div>
                <div style={{paddingTop:'30px'}}>
                    <img src="../images/login/bigbasket.svg" alt="bigbasket" />
                </div>
                <div style={{paddingTop:'40px'}}>
                    <img src="../images/login/gromo.svg" alt="gromo"/>
                </div>
                <div style={{paddingTop:'45px'}}>
                    <img src="../images/login/bank-of-baroda.png" alt="bank-of-baroda"/>
                </div>
                <div style={{paddingTop:'20px'}}>
                    <img src="../images/login/audi.png" alt="audi"/>
                </div>
                <div style={{paddingTop:'25px'}}>
                    <img src="../images/login/olx.png" alt="olx" style={{width:'80px'}}/>
                </div>
                <div>
                    <img src="../images/login/skoda.png" alt="skoda"/>
                </div>
            </div>
        </section>
        <section id={style['execute-any-number']}> 
            <div className='container'>
                <div className={style['trusign-power']}>
                    <div className={style['left-side-power-content']}>
                        <h3>Access the platform for free and execute any number of documents with a pay-as-you-go model.</h3>
                        <p>
                            TruSign empowers a wide range of enterprises, from high-growth unicorns to the largest banks, to seamlessly transition into a paperless environment. With its advanced technology, TruSign drives unparalleled efficiency, sustainability, and security across diverse industries.
                            <br/>Signing documents like invoices, POs, challans, employment agreements, offer letters, and vendor contracts. Workflows-based approvals and signing of documents.
                        </p>
                    </div>
                    <div className={style['right-side-power-image']}>
                        <img src='../images/landing-page/execute-power.png'  alt=''/>
                    </div>
                </div>
            </div>
        </section>
        <section id={style['why-trusign']}>
            <div className='container'>
                <div className={style['sign-with-ease']}>
                    <p>Why <span>TruSign</span></p>
                    <h4>Sign with ease, anywhere, anytime</h4>
                </div>
                <div className={style['features']}>
                    <div className={style['main-features']}>
                        <div className={style['mainfeature-images']}>
                            <img src='../images/landing-page/user-friendly-interface.png' alt=''/>
                        </div>
                        <div>
                            <h5>User-friendly interface</h5>
                            <p>User-friendly interface that allows you to easily upload a document, select signatories, and set a signing order.</p>
                        </div>
                    </div>
                    <div className={style['main-features']}>
                        <div className={style['mainfeature-images']}>
                            <img src='../images/landing-page/no-xtra-fee.png' alt=''/>
                        </div>
                        <div>
                            <h5>No Extra fee</h5>
                            <p>End to end payment and financial management in a single solution. Choose the right platform for your needs today.</p>
                        </div>
                    </div>
                    <div className={style['main-features']}>
                        <div className={style['mainfeature-images']}>
                            <img src='../images/landing-page/advanced-security.png' alt='' />
                        </div>
                        <div>
                            <h5>Advanced security features</h5>
                            <p>TruSign uses industry-leading security measures, including multi-factor authentication and encryption</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section id={style['flexible-eSignature']}>
                <div className='container'>
                    <div className={style['why-choose-us']}>
                        <div className={style['left-content-image']}>
                            <img src='../images/landing-page/flexible-signature.png' alt='flexible-signature' />
                        </div>
                        <div className={style['right-side-content']}>
                            <div>
                                <span>Why Choose us</span>
                                <h4>Flexible TruSign Workflow</h4>
                            </div>
                            <ul>
                                <li>TruSign supports multiple file formats, including PDF, Word, Excel, and other commonly-used file types.</li>
                                <li>Versatile file compatibility</li>
                                <li>Customizable file settings</li>
                                <li>Seamless document conversion</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id={style['multiple-file']}>
                <div className='container'>
                    <div className={style['why-choose-us']}>
                        <div className={style['right-side-content']}>
                            <div>
                                <span>Why Choose us</span>
                                <h4>Support multiple file format</h4>
                            </div>
                            <ul>
                                <li>Offers a wide range of features and capabilities to meet the needs of different users and use cases.</li>
                                <li>Multiple signature types</li>
                                <li>Customizable templates</li>
                                <li>Unlimited e-signs</li>
                            </ul>
                        </div>
                        <div className={style['left-content-image']}>
                            <img src='../images/landing-page/multiple-file-format.png' alt='flexible-signature' />
                        </div>
                    </div>
                </div>
            </section>
            <section id={style['real-time-document']}>
                <div className='container'>
                    <div className={style['why-choose-us']}>
                        <div className={style['left-content-image']}>
                            <img src='../images/landing-page/real-time-document.png' alt='real-time-document' />
                        </div>
                        <div className={style['right-side-content']}>
                            <div>
                                <span>Why Choose us</span>
                                <h4>Real-time document sharing and editing</h4>
                            </div>
                            <ul>
                                <li>Your product should allow users to share and edit documents in real-time, which can help streamline collaboration and reduce the time it takes to get documents signed</li>
                                <li>Ultimately leading to faster & efficient results.</li>
                                <li>Reduce errors</li>
                                <li>Save time</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id={style['streaming-inline']}>
                <div className='container'>
                    <div className={style['streamline-signature']}>
                        <div className={style['work-process']}>
                            <span>How it works</span>
                            <h4>Streamline your signature process</h4>
                        </div>
                        <div className={style['step-in-process']}>
                            <div className={style['upload-document']}>
                                <div>
                                    <img src='../images/landing-page/upload-document.png' alt='upload-document'/>
                                </div>
                                <div>
                                    <span>01</span>
                                    <h5>Upload document</h5>
                                    <span>The first step to signing a document with TruSign is to upload your document to the platform.</span>
                                </div>
                            </div>
                            <div className={style['add-signer']}>
                                <div>
                                    <img src='../images/landing-page/add-signer.png' alt='add-signer'/>
                                </div>
                                <div>
                                    <span>02</span>
                                    <h5>Add signer</h5>
                                    <span>you can add the signer or signers to the document. This can be done by selecting the "Add Signer" button </span>
                                </div>
                            </div>
                            <div className={style['Document-e-signed']}>
                                <div>
                                    <img src='../images/landing-page/document-esign.png' alt='document-esign'/>
                                </div>
                                <div>
                                    <span>03</span>
                                    <h5>Document e-signed</h5>
                                    <span> After the signers have received the document, they can e-sign the document using TruSign's intuitive and easy-to-use e-signature interface.</span>
                                </div>
                            </div>
                            <div className={style['Shared-with-all-parties']}>
                                <div>
                                    <img src='../images/landing-page/shared-parties.png' alt='shared-parties'/>
                                </div>
                                <div>
                                    <span>04</span>
                                    <h5>Shared with all parties</h5>
                                    <span> Once the document is e-signed, you can share it with all parties who need access to the document</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={style['affordable-e-solution']}>
                <div className='container'>
                    <div className={style['pricing']}>
                        <div>
                            <span>PRICING</span>
                            <h4>Get the most affordable<br/> e-signature solution</h4>
                            <div className={style['document-pricing']}>
                                <a href="https://dashboard.invincibleocean.com/esign" rel="noreferrer" target="_blank"><button className="btn primary-button">Get started</button></a>
                                <p>₹20/- <span>per document</span></p>
                            </div>
                        </div>
                        <div>
                            <img src='../images/landing-page/affordable-e-sign-solution.png' alt='' /> 
                        </div>
                    </div>
                </div>
            </section>
            <section id={style['trusign-pricing']}>
                <div className='container'>
                    <div className={style['pricing-trusign']}>
                        <div className={style['tru-sign-pricing']}>
                            <span>Pricing</span>
                            <h4>TruSign Pricing</h4>
                            <h3>Configure a cost estimate that fits your unique business or personal needs with Invincible</h3>
                        </div>
                        <div className={style['functionality-esign']}>
                            <div className={style['slabing']}>
                                <div className={style['slab-1']}>
                                    <div className={style['slab-points']}>
                                        <h4>Pro</h4>
                                        <hr/>
                                        <ul>
                                            <li>Aadhaar eSign- Rs 20 Per Document</li>
                                            <li>Email/Mobile OTP Signing- Rs 10 Per Document</li>
                                            <li>Credit Validity- 1 Year</li>
                                            <li>19*7 Support</li>
                                            <li>Dedicated Account Manager</li>
                                            <li>Phone and Email Support</li>
                                            <li>Analytics/Charts/Spend</li>
                                        </ul>
                                    </div>
                                    <a href="https://invincibleocean.com/contact-us/" rel="noreferrer" target="_blank"><button className='btn secondary-button'>Contact us</button></a>
                                </div>
                            </div>
                            <div className={style['slabing']}>
                                <div className={style['slab-1']}>
                                    <div className={style['slab-points']}>
                                        <h4>Premium</h4>
                                        <hr/>
                                        <ul>
                                            <li>Aadhaar eSign- Rs 30 Per Document</li>
                                            <li>Email/Mobile OTP Signing- Rs 15 Per Document</li>
                                            <li>Credit Validity 6 Months</li>
                                            <li>Phone and Email Support</li>
                                            <li>Analytics</li>
                                        </ul>
                                    </div>
                                    <a href="https://invincibleocean.com/contact-us/" rel="noreferrer" target="_blank"><button className='btn secondary-button '>Contact us</button></a>
                                </div>
                            </div>
                            <div className={style['slabing']}>
                                <div className={style['slab-1']}>
                                    <div className={style['slab-points']}>
                                        <h4>Standard</h4>
                                        <hr/>
                                        <ul>
                                            <li>No Minimum Commitment</li>
                                            <li>Pay as you go Model</li>
                                            <li>Aadhaar eSign- Rs 40 Per Document</li>
                                            <li>Email/Mobile OTP Signing- Rs  20Per Document</li>
                                            <li>Credit Validity 3 Months</li>
                                            <li>Email Support</li>
                                        </ul>
                                    </div>
                                    <a href="https://invincibleocean.com/esign/" rel="noreferrer" target="_blank"><button className='btn secondary-button '>log  in to dashboard</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={style['benefits']}>
                <div className='container'>
                    <div className={style['streamline-signature']}>
                        <div className={style['e-sign-benefits']}>
                            <span>Benefits</span>
                            <h4>Enjoy faster, more secure signing with TruSign</h4>
                        </div>
                        <div className={style['functionality']}>
                            <div className={style['increased-efficiency']}>
                                <div>
                                    <img src='../images/landing-page/increased-efficiency.png' alt='increased-efficiency'/>
                                </div>
                                <div>
                                    <h5>Increased Efficiency</h5>
                                    <span>TruSign can streamline the document signing process by eliminating the need for physical signatures. </span>
                                </div>
                            </div>
                            <div className={style['increased-efficiency']}>
                                <div>
                                    <img src='../images/landing-page/improved-security.png' alt='improved-security'/>
                                </div>
                                <div>
                                    <h5>Improved Security</h5>
                                    <span>TruSign is more secure than physical signatures because they use advanced authentication methods and encryption to ensure the authenticity and integrity of the signed documents.</span>
                                </div>
                            </div>
                            <div className={style['increased-efficiency']}>
                                <div>
                                    <img src='../images/landing-page/increased-complliance.png' alt='increased-complliance'/>
                                </div>
                                <div>
                                    <h5>Increased Compliance</h5>
                                    <span>TruSign is legally binding and enforceable, making them a reliable way to meet compliance requirements. </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section  id={style['pro-solution']}>
                <div className='container'>
                        <div className={style['information-box']}>
                            <div className={style['left-side-info']}>
                                <div className={style['address-info']}>
                                    <span>Contact us</span>
                                    <h4>Sign like a pro with TruSign's<br/> e-signature solution</h4>
                                </div>
                                <div className={style['logo-icons']}>
                                    <div>
                                        <div>
                                            <img src='../images/landing-page/location-logo.png' alt='location-logo'/>
                                        </div>
                                        <div>
                                            <p>Our location</p>
                                            <span>201-A, SAS Tower, Sec-38, Gurugram </span>
                                            <span>122001, Haryana</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <img src='../images/landing-page/phone-icon.png' alt='phone-icon' />
                                        </div>
                                        <div>
                                            <p>Phone number</p>
                                            <span>+91 95996 67779</span>
                                            {/* <span>+91 95996 67779</span> */}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <img src='../images/landing-page/email-icon.png' alt='email-icon'/>
                                        </div>
                                        <div>
                                            <p>Email address</p>
                                            <span>contact@invincibleocean.com</span>
                                            {/* <span>contact@invincibleocean.com</span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style['personal-info']}>
                                <form onSubmit={sendRequest}>
                                    <div>
                                        <label>Name</label>
                                        <input  type="text" placeholder='Enter Name' value={name} onChange={(e) => setName(e.target.value)} required/>
                                    </div>
                                    <div>
                                        <label>Email</label>
                                        <input type="text" placeholder='Enter Email' value={email} onChange={(e) => setEmail(e.target.value)} required/>
                                    </div>
                                    <div>
                                        <label>Mobile number</label>
                                        <input type="text" placeholder='Enter number' value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                                    </div>
                                    <div>
                                        <label>Message</label>
                                        <textarea className="form-control mb-3" rows="3 " placeholder='Enter message here' value={contactMessage} onChange={(e) => setContactMessage(e.target.value)} required/>
                                    </div>
                                    {loader 
                                        ?<button className='btn primary-button w-100' disabled><i className="fa fa-circle-o-notch fa-spin"></i> Sending</button>
                                        :<button className='btn primary-button w-100'>Send message</button>
                                    }
                                </form>
                            </div>
                        </div>
                </div>
            </section>
        <Faqs heading="Everything you need to know about e-signing" faqs={faqs} />
        <section id={style['convenient-e-signatures']}>
            <div className='container'>
                <div className={style['inside-black-box']}>
                    <h4>Get started with TruSign today and experience the benefits of secure and convenient e-signatures.</h4>
                    <a href="https://dashboard.invincibleocean.com/esign" rel="noreferrer" target="_blank"><button className="btn primary-button">Get started</button></a>
                </div>
            </div>
        </section>
        <Footer />
        
        {/* =============== Request demo Modal by saif (20/06/2023) =====================*/}
        {showModal && (
        <div className={style["requestdemo-modal-container"]}>
            <div className={style["requestdemo-modal-background"]} onClick={()=>{setShowModal(false)}} ></div>
            <div className={style["requestdemo-modal-content"]}>
                <RequestDemo  setShowModal={setShowModal} sendRequest={sendRequest} name={name} email={email} phone={phone} contactMessage={contactMessage} setName={setName} setEmail={setEmail}  setPhone={setPhone} setContactMessage={setContactMessage} resetFunction={resetFunction} />
            </div>
        </div>
        )} 
        {/* =================================================================== */}
    </>);
}
